


























































import { Component, Vue } from 'vue-property-decorator';
import { metaInfo } from '@/utils/seo/metaInfo';
import { buildUrl } from '@/utils/url';

@Component({
  components: {},
  metaInfo: metaInfo({
    title: 'Půjč si mikrobus | Kakadoo',
    url: buildUrl('/pujc-si-mikrobus'),
    description: 'Půjčte si mikrobus. Vše vyřídíte jedoduše online na stránkách Kakadoo.cz!'
  })
})
export default class Partner extends Vue {
  private fullWidthImage = false;
  private fullWidthImageUrl = '';

  private images: string[] = [
    'https://kakadoo-public.s3.eu-central-1.amazonaws.com/vans/fiat-xl-4ar9468/full/mikrobus-1-full.jpg',
    'https://kakadoo-public.s3.eu-central-1.amazonaws.com/vans/fiat-xl-4ar9468/full/mikrobus-3-full.jpg',
    'https://kakadoo-public.s3.eu-central-1.amazonaws.com/vans/fiat-xl-4ar9468/full/mikrobus-3.5-full.jpg',
    'https://kakadoo-public.s3.eu-central-1.amazonaws.com/vans/fiat-xl-4ar9468/full/mikrobus-2-full.jpg',
    'https://kakadoo-public.s3.eu-central-1.amazonaws.com/vans/fiat-xl-4ar9468/full/mikrobus-4-full.jpg',
    'https://kakadoo-public.s3.eu-central-1.amazonaws.com/vans/fiat-xl-4ar9468/full/mikrobus-5-full.jpg',
    'https://kakadoo-public.s3.eu-central-1.amazonaws.com/vans/fiat-xl-4ar9468/full/mikrobus-7-full.jpg',
    'https://kakadoo-public.s3.eu-central-1.amazonaws.com/vans/fiat-xl-4ar9468/full/mikrobus-6-crop-full.jpg',
    'https://kakadoo-public.s3.eu-central-1.amazonaws.com/vans/fiat-xl-4ar9468/full/mikrobus-8-full.jpg'
  ];

  private showModal(imageUrl: string) {
    this.fullWidthImage = true;
    this.fullWidthImageUrl = imageUrl;
  }

  private closeModal() {
    this.fullWidthImage = false;
    this.fullWidthImageUrl = '';
  }
}
